const indexSearch = () => {

	const searchInput = document.getElementById("index-search");
  if (!searchInput) return; // Exit if the search input is not found

  const tableRows = document.querySelectorAll("tbody tr");

  searchInput.addEventListener("keyup", function() {
    const query = this.value.toLowerCase();
    tableRows.forEach(row => {
      const cells = row.querySelectorAll("td");
      const match = Array.from(cells).some(cell => cell.textContent.toLowerCase().includes(query));
      if (match) {
        row.style.display = "";
      } else {
        row.style.display = "none";
      }
    });
  });

};

export { indexSearch };